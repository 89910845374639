import { $cartItems, removeCartItem } from "@/store/cart-store";
import { numberFormat } from "@acelords/js-utils";
import { useStore } from "@nanostores/react";
import { X } from "lucide-react";
import Image from "./image";

interface Props {}

const CartSheetItems = ({}: Props) => {
  const cartItems = useStore($cartItems);

  return (
    <div className="space-y-6 mb-10">
      {cartItems.length === 0 && (
        <div className="space-y-4">
          <p className="text-gray-500">You have no items in your cart.</p>
        </div>
      )}

      {cartItems.map((item, index) => {
        const theShop = item.shop;
        let url;
        if (theShop) {
          url = `/shops/${theShop.slug}/products/${item.product.slug}`;
        } else {
          url = `/products/${item.product.slug}`;
        }

        if (item.variant) {
          url += `?variant=${item.variant.id}`;
        }

        return (
          <div
            key={item.cuid}
            data-cuid={item.cuid}
            className="space-y-4 relative">
            <div className="grid grid-cols-4 gap-2 items-start">
              <div className="">
                <a href={url}>
                  <Image
                    src={
                      item.variant?.featured_img || item.product.featured_img
                    }
                    alt={item.product.title}
                    className="h-20 w-auto"
                  />
                </a>
              </div>

              <div className="col-span-3">
                <a href={url}>
                  <h3 className="font-medium text-primary text-sm">
                    {item.product.title}
                  </h3>
                </a>

                {item.variant && (
                  <div className="text-sm text-gray-700">
                    ~ {item.variant.display_name}
                  </div>
                )}

                <div className="flex justify-between items-center">
                  <div className="text-sm text-gray-500">
                    {item.quantity} added
                  </div>
                  <div className="font-bold text-left text-lg">
                    {numberFormat(item.price * item.quantity, true)}
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute -right-1 -top-5">
              <button
                className="text-gray-400 hover:bg-red-500 hover:text-white rounded-full p-1 transition-colors"
                type="button"
                onClick={() => removeCartItem(item.cuid)}
                aria-label={`Remove ${item.product.title} from cart`}>
                <X className="size-4" />
              </button>
            </div>

            {index < cartItems.length - 1 && (
              <div className="border-t border-gray-200 mt-6" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CartSheetItems;
