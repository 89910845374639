import CartSheetItems from "@/components/cart-sheet-items";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { $cartItemsCount, $cartSheetOpen } from "@/store/cart-store";
import type { Shop, User } from "@/types";
import { useStore } from "@nanostores/react";
import { ShoppingCart } from "lucide-react";
import { useState } from "react";

interface Props {
  shop: Shop;
  user?: User | null;
}

const CartButton = ({ shop, user }: Props) => {
  const cartCount = useStore($cartItemsCount);
  const isCartsheetOpen = useStore($cartSheetOpen);

  return (
    <>
      <button
        className="relative p-2 rounded-full hover:opacity-80 transition-opacity"
        aria-label="Shopping Cart"
        onClick={() => $cartSheetOpen.set(true)}>
        <ShoppingCart className="size-6 text-primary" />
        <span className="absolute -top-1 -right-1 bg-kp-primary text-white font-bold size-5 rounded-full text-xs flex items-center justify-center">
          {cartCount}
        </span>
      </button>

      <Sheet open={isCartsheetOpen} onOpenChange={$cartSheetOpen.set}>
        <SheetContent className="h-full flex flex-col max-w-md shadow-xl">
          <SheetHeader>
            <SheetTitle>Cart</SheetTitle>
          </SheetHeader>

          <div className="flex flex-col w-full h-full pb-8">
            <div className="flex-grow overflow-y-auto scrollbar-hide">
              <CartSheetItems />
            </div>

            <Button
              asChild
              className="w-full hover:opacity-80 transition-opacity">
              <a href={`/shops/${shop.slug}/cart`}>View Cart</a>
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default CartButton;
